// assets
import SettingsIcon from '@mui/icons-material/Settings';
import CollectionsIcon from '@mui/icons-material/Collections';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TuneIcon from '@mui/icons-material/Tune';
import permissions from 'permissions';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const webshopssettings = {
    id: 'webshops',
    title: 'GC.Webshops Settings',
    type: 'group',
    feature: "WEBSHOPS",
    children: [
        {
            id: "webshopsMainList",
            title: "Webshops",
            type: "item",
            url: "/webshops/main-list",
            icon: StorefrontIcon,
            breadcrumbs: false,
            requiredPermission: permissions.WEBSHOP_GLOBAL_ADMIN,
        },
        {
            id: "webshops",
            title: "Global",
            type: "collapse",
            icon: PublicIcon,
            breadcrumbs: false,
            requiredPermission: permissions.WEBSHOP_GLOBAL_ADMIN,
            children: [
                {
                    id: "webshop",
                    title: "Webshop",
                    type: "item",
                    url: "/webshops/global",
                    icon: SettingsApplicationsIcon,
                    breadcrumbs: false,
                },
                {
                    id: "webshopCore",
                    title: "Core",
                    type: "item",
                    url: "/webshops/global/core",
                    icon: TuneIcon,
                    breadcrumbs: false,
                },
            ]
        },
        {
            id: "webshops",
            title: "Business",
            type: "collapse",
            icon: SettingsIcon,
            breadcrumbs: false,
            requiredPermission: permissions.WEBSHOP_ADMIN,
            children: [
                {
                    id: "branding",
                    title: "Branding",
                    type: "item",
                    url: "/webshops/branding",
                    icon: CollectionsIcon,
                    breadcrumbs: false,
                },
                {
                    id: "business",
                    title: "Business",
                    type: "item",
                    url: "/webshops/business",
                    icon: BusinessCenterIcon,
                    breadcrumbs: false,
                },
                {
                    id: "contact",
                    title: "Contact",
                    type: "item",
                    url: "/webshops/contact",
                    icon: ContactMailIcon,
                    breadcrumbs: false,
                },
                {
                    id: "cart",
                    title: "Cart",
                    type: "item",
                    url: "/webshops/cart",
                    icon: ShoppingCartIcon,
                    breadcrumbs: false,
                },
                {
                    id: "b2b2b",
                    title: "B2B2B",
                    type: "item",
                    url: "/webshops/b2b2b",
                    icon: PeopleIcon,
                    breadcrumbs: false,
                },
            ]
        },
    ]
};

export default webshopssettings;
