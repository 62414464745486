import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const WebshopsBranding = Loadable(
    lazy(() => import("components/webshops/branding/index"))
);

const WebshopsContact = Loadable(
    lazy(() => import("components/webshops/contact/index"))
);

const WebshopsCart = Loadable(
    lazy(() => import("components/webshops/cart/index"))
);

const WebshopsBusiness = Loadable(
    lazy(() => import("components/webshops/business/index"))
);

const WebshopsB2b2b = Loadable(
    lazy(() => import("components/webshops/b2b2b/index"))
);

const WebshopsMainList = Loadable(
    lazy(() => import("components/webshops/mainList/index"))
);

const WebshopsGlobal = Loadable(
    lazy(() => import("components/webshops/global/index"))
);

const WebshopsCore = Loadable(
    lazy(() => import("components/webshops/core/index"))
);



const WebshopsRoutes = {
    path: "/webshops",
    element: <MainLayout />,
    children: [
        {
            path: "/webshops/branding",
            element: <WebshopsBranding />,
        },
        {
            path: "/webshops/business",
            element: <WebshopsBusiness />,
        },
        {
            path: "/webshops/contact",
            element: <WebshopsContact />,
        },
        {
            path: "/webshops/cart",
            element: <WebshopsCart />,
        },
        {
            path: "/webshops/b2b2b",
            element: <WebshopsB2b2b />,
        },
        {
            path: "/webshops/global",
            element: <WebshopsGlobal />,
        },
        {
            path: "/webshops/global/core",
            element: <WebshopsCore />,
        },
        {
            path: "/webshops/main-list",
            element: <WebshopsMainList />,
        },
    ],
};

export default WebshopsRoutes;
